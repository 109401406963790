<template>
  <div class="row">
    <div class="col-md-5">
      <div class="form-group">
        <label for="seo_title">عنوان الخبر</label>
        <Field
          name="seo_title"
          id="seo_title"
          :value="seoData.seo_title"
          @input="$emit('update', 'seo_title', $event.target.value)"
          placeholder="عنوان الخبر"
          class="form-control form-control-solid"
        />
        <span class="hint">* يجب ان لا يتجاوز عنوان الخبر 60 حرفا</span>
      </div>

      <div class="form-group mt-3">
        <label for="seo_description">ملخص الخبر</label>
        <textarea
          rows="5"
          :value="seoData.seo_description"
          @input="$emit('update', 'seo_description', $event.target.value)"
          name="seo_description"
          id="seo_description"
          class="form-control input-rounded form-control-solid"
          placeholder="ملخص الخبر"
        ></textarea>
        <span class="hint">* يجب ان لا يتجاوز وصف الخبر 230 حرفا</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import {  Field, ErrorMessage } from "vee-validate";
import {ref} from 'vue';
const props = defineProps(['seoData'])
const seo_title = ref('')
const seo_description = ref('')

</script>
