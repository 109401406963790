<template>
  <div class="row">
    <div class="col-md-5">
      <div class="form-group">
        <label class="d-block mt-0">إضافة صور</label>
        <button class="btn btn-upload placeholder-image" type="button" @click="showUploadCenter = true">
<!--          <UploadFilled />-->
          <small >upload your images from here</small>
        </button>
      </div>
    </div>
    <div class="col-md-6">
      <div class="card-images">
        <div class="card-img" v-for="photo in photos" :key="photo.id">
          <img :src="STORAGE_URL + '150x150/' + photo.url" :alt="photo.name" />
        </div>
      </div>
    </div>
    <el-dialog   :destroy-on-close=false
                 title="مركز الرفع"
                v-model="showUploadCenter"
    >
      <UploadCenter :is-single-upload="false" @save="handleSave" />
    </el-dialog>
  </div>
</template>

<script setup>
import UploadCenter from "@/components/uploadCenter";
import {UploadFilled} from "@element-plus/icons-vue";
import {computed, ref} from "vue";

const props = defineProps(
    {
      prevPhotos: {
        default: []
      }
    }
)

const emits = defineEmits(['update'])

let showUploadCenter = ref(false);
const photos         = ref(props.prevPhotos);
const STORAGE_URL = computed(()=>process.env.VUE_APP_IMAGE_REMOTE_URL)
const closeUploadCenter = () => showUploadCenter.value = false;

const handleSave = photosList => {
  photos.value = [...photos.value,...photosList];
  const mapped_photos = photos.value.map(photo=>photo.url)
  emits('update','photos',mapped_photos);
  closeUploadCenter();
}
</script>

<style lang="scss">
.el-dialog {
  --el-dialog-width: 70%;

  .el-dialog__header {
    border-bottom: 1px solid #dee2e6;
  }
}
[dir=rtl] .el-dialog__headerbtn {
  right: unset;
  left: 20px;
}
.btn-upload {
  margin-inline-start: 5px;
  max-width: 100%;
  min-height: 350px;
  overflow: hidden;
  margin-top: 15px;
  //flex-direction: column;
  align-items: flex-end;

  small {
    margin-bottom: 5%;
    display: block;
    color: #9598a7;
  }
  path {
      fill: #c4c6ce;
    }
}

.card-images {
  margin-top: 3.5rem;
  display: flex;
  gap: .5rem;
  flex-wrap: wrap;

  .card-img {
    max-width: 150px;
  }
}
</style>
