<template>
  <div class="details-form">
    <div class="row">
      <div class="col-md-8">
        <div class="row">
          <div class="col-lg-6">
            <div class="form-group">
              <label for="slug">عنوان إرشادي</label>
              <Field
                  name="slug"
                  id="slug"
                  :value="newsDetails.highlight_title"
                  @input="$emit('update', 'highlight_title', $event.target.value)"
                  placeholder="عنوان إرشادي"
                  class="form-control form-control-solid"

              />
              <!--              :class="{'border-red' : errors.slug}"-->
              <!--              :rules="isRequired"-->
              <!--              <span class="text-error">{{ errors.slug }}</span>-->
            </div>
          </div>

          <div class="col-lg-6">
            <div class="form-group">
              <label for="news-title">عنوان الخبر</label>
              <Field
                  name="title"
                  id="news-title"
                  :value="newsDetails.title"
                  @input="$emit('update', 'title', $event.target.value)"
                  placeholder="عنوان الخبر"
                  class="form-control form-control-solid"
                  :class="{'border-red' : errors.title}"
                  :rules="isRequired"
              />
              <span class="text-error">{{ errors.title ? 'الرجاء ادخال عنوان الخبر' : '' }}</span>
            </div>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-lg-6">
            <div class="form-group">
              <label for="original">المصدر</label>
              <Field
                  name="original"
                  id="original"
                  :value="newsDetails.source"
                  @input="$emit('update', 'source', $event.target.value)"
                  placeholder="المصدر"
                  class="form-control form-control-solid"
              />
              <!--              :rules="isRequired"-->
              <!--              :class="{'border-red' : errors.original}"-->

              <!--              <span class="text-error">{{ errors.original ? 'الرجاء ادخال المصدر'  : ''}}</span>-->

            </div>
          </div>

          <div class="col-lg-6">
            <div class="form-group tags-control">
              <label>كلمات مفتاحية</label>
              <el-select
                  class="form-control position-relative multi-select"
                  v-model="selected_tags"
                  multiple
                  allow-create
                  filterable
                  remote
                  reserve-keyword
                  default-first-option
                  placeholder="Please enter a keyword"
                  :remote-method="handleSearchTags"
                  :loading="isTagsLoading"
                  loading-text="جاري البحث ...">
                <el-option
                    v-for="item in tags"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                </el-option>
              </el-select>
            </div>
          </div>
        </div>

        <div class="form-group mt-3">
          <label for="summary">ملخص الخبر</label>
          <textarea
              rows="5"
              id="summary"
              :value="newsDetails.summary"
              @input="$emit('update', 'summary', $event.target.value)"
              name="summary"
              class="form-control input-rounded form-control-solid"
              placeholder="ملخص الخبر"
          ></textarea>
        </div>

        <div class="form-group mt-3 "
             :class="{'border-2 border border-danger' : contentError && !newsDetails?.content?.length}">
          <CustomCKEditor :content="newsDetails.content" @updateContent="val=>$emit('update','content',val)"/>
        </div>
        <span
            class="text-error">{{
            contentError && !newsDetails.content.length ? 'الرجاء ادخال محتوى الخبر' : ''
          }}</span>

        <div class="form-group mt-3">
          <label for="video">إضافة فيديو</label>
          <Field
              name="video"
              id="video"
              :value="newsDetails.video"
              @input="$emit('update', 'video', $event.target.value)"
              placeholder="رابط الفيديو"
              class="form-control form-control-solid"
          />
        </div>
      </div>

      <div class="col-lg-4">
        <div class="form-group news-image">
          <label
              class="placeholder-image"
              @click="handleOpenUploadCenter"
          >
            <img
                class="w-100"
                height="220"
                v-if="newsPhoto"
                :src="APP_URL + newsPhoto"
                alt=""
            />

            <!--            <img src="/media/cloud-img.png" v-else class="img-float" alt=""/>-->
          </label>
          <Field
              name="image_description"
              id="image_description"
              :value="newsDetails.image_description"
              @input="$emit('update', 'image_description', $event.target.value)"
              placeholder="أدخل وصف الصورة هنا"
              class="form-control form-control-solid"
          />
        </div>


        <el-dialog
            @close="closeUploadCenter"
            v-if="showUploadCenter"
            :destroy-on-close="false"
            title="مركز الرفع"
            v-model="showUploadCenter"
        >
          <UploadCenter
              :is-single-upload="isSingleUpload"
              @save="handleAddPhoto"
          />
        </el-dialog>

        <div class="form-group text-center mt-4" dir="ltr">
          <label for="date" class="text-end d-block">التاريخ</label>
          <DatePicker mode="dateTime" v-model="date">
            <template v-slot="{ inputValue, inputEvents }">
              <input
                  id="date"
                  class="px-2 py-1 form-control text-end form-control-solid border rounded focus:outline-none focus:border-blue-300"
                  :value="inputValue"
                  v-on="inputEvents"
              />
            </template>
          </DatePicker>
        </div>

        <div class="form-group mt-3">
          <h5>التصنيفات</h5>
          <el-select filterable
                     name="category"
                     class="w-100  form-control-solid"
                     v-model="category_id"
                     :rules="isRequired"
                     :class="{'border-red' : errors.category}"
                     required
          >
            <el-option-group
                v-for="item in categories"
                :key="item.id"
                :label="item.name"
            >
              <el-option
                  v-for="subCategory in item.children"
                  :key="subCategory.id"
                  :label="subCategory.name"
                  :value="subCategory.id"
              >
              </el-option>
            </el-option-group>
          </el-select>
          <span class="text-error">{{ errors.category ? 'الرجاء اختيار التصنيف' : '' }}</span>
        </div>

        <div class="form-group mt-3">
          <div class="checkbox-group">
            <input
                type="checkbox"
                id="is_news_ticker"
                name="is_news_ticker"
                :value="newsDetails.is_news_ticker"
                @input="
                $emit('update', 'is_news_ticker', !newsDetails.is_news_ticker)
              "
                :checked="newsDetails.is_news_ticker"

            />
            <label for="is_news_ticker">
              شريط الاخبار - ظهور عنوان الخبر في شريط متحرك
            </label>
          </div>

          <div class="checkbox-group">
            <input
                type="checkbox"
                id="is_main_news"
                name="is_main_news"
                :value="newsDetails.is_main_news"
                @input="
                $emit('update', 'is_main_news', !newsDetails.is_main_news)
              "
                :checked="newsDetails.is_main_news"

            />
            <label for="is_main_news"> خبر رئيسي </label>
          </div>

          <div class="checkbox-group">
            <input
                type="checkbox"
                id="is_special_news"
                name="is_special_news"
                :value="newsDetails.is_special_news"
                @input="
                $emit('update', 'is_special_news', !newsDetails.is_special_news)
              "
                :checked="newsDetails.is_special_news"

            />
            <label for="is_special_news"> أخبار مميزة </label>
          </div>

          <div class="checkbox-group">
            <input
                type="checkbox"
                id="share_facebook"
                name="share_facebook"
                :value="newsDetails.share_facebook"
                @input="
                $emit('update', 'share_facebook', !newsDetails.share_facebook)
              "
                :checked="newsDetails.share_facebook"

            />
            <label for="share_facebook"> نشر تلقائي على الفيسبوك </label>
          </div>

          <div class="checkbox-group">
            <input
                type="checkbox"
                id="share_facebook_o"
                name="share_facebook_o"
                :value="newsDetails.share_facebook_o"
                @input="
                $emit(
                  'update',
                  'share_facebook_o',
                  !newsDetails.share_facebook_o
                )
              "
                :checked="newsDetails.share_facebook_o"

            />
            <label for="share_facebook_o">
              نشر تلقائي على الفيسبوك (الصفحة الرسمية)
            </label>
          </div>

          <div class="checkbox-group">
            <input
                type="checkbox"
                id="share_twitter"
                name="share_twitter"
                :value="newsDetails.share_twitter"
                @input="
                $emit('update', 'share_twitter', !newsDetails.share_twitter)"
                :checked="newsDetails.share_twitter"
            />
            <label for="share_twitter"> نشر تلقائي على تويتر </label>
          </div>

          <div class="checkbox-group">
            <input
                type="checkbox"
                id="notification"
                name="notification"
                :value="newsDetails.is_notify"
                @input="$emit('update', 'is_notify', !newsDetails.is_notify)"
                :checked="newsDetails.is_notify"

            />
            <label for="notification"> إرسال إشعار للهاتف </label>
          </div>

          <div class="checkbox-group">
            <input
                type="checkbox"
                id="instant"
                name="instant"
                :value="newsDetails.is_instant"
                @input="$emit('update', 'is_instant', !newsDetails.is_instant)"
                :checked="newsDetails.is_instant"

            />
            <label for="instant"> instant article </label>
          </div>

          <div class="checkbox-group">
            <input
                type="checkbox"
                id="amp"
                name="amp"
                :value="newsDetails.is_amp"
                @input="$emit('update', 'is_amp', !newsDetails.is_amp)"
                :checked="newsDetails.is_amp"

            />
            <label for="amp"> AMP </label>
          </div>

          <div class="checkbox-group">
            <input
                type="checkbox"
                id="use_watermark"
                name="use_watermark"
                :value="newsDetails.use_watermark"
                @input="
                $emit('update', 'use_watermark', !newsDetails.use_watermark)
              "
                :checked="newsDetails.use_watermark"

            />
            <label for="use_watermark"> استخدام علامة مائية للصورة </label>
          </div>

          <div class="checkbox-group">
            <input
                type="checkbox"
                id="is_particular_news"
                name="is_particular_news"
                :value="newsDetails.is_particular_news"
                @input="
                $emit(
                  'update',
                  'is_particular_news',
                  !newsDetails.is_particular_news
                )
              "
                :checked="newsDetails.is_particular_news"

            />
            <label for="is_particular_news"> تقارير خاصة </label>
          </div>

          <div class="checkbox-group">
            <input
                type="checkbox"
                id="is_shown_in_template"
                name="is_shown_in_template"
                :value="newsDetails.is_shown_in_template"
                @input="
                $emit(
                  'update',
                  'is_shown_in_template',
                  !newsDetails.is_shown_in_template
                )
              "
                :checked="newsDetails.is_shown_in_template"

            />
            <label for="is_shown_in_template"> الظهور في قالب </label>
          </div>

          <div class="checkbox-group">
            <input
                type="checkbox"
                id="is_hidden_news"
                name="is_hidden_news"
                :value="newsDetails.is_hidden_news"
                @input="
                $emit('update', 'is_hidden_news', !newsDetails.is_hidden_news)
              "
                :checked="newsDetails.is_hidden_news"
            />

            <label for="is_hidden_news"> حبر مخفي </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Field, ErrorMessage} from "vee-validate";
import {DatePicker} from "v-calendar";
import {fetchSearchTags} from "@/services/news";
import UploadCenter from "@/components/uploadCenter";
import CustomCKEditor from "@/components/editor";

import store from '@/store'
import {mapGetters} from "vuex";
import {computed} from "vue";
import {dateFormatter} from "../../../utils/formatDate";

export default {
  name      : "News-Details",
  components: {
    Field,
    ErrorMessage,
    DatePicker,
    UploadCenter,
    CustomCKEditor
  },
  props     : ["newsDetails", "errors", "contentError"],
  data()
  {
    return {
      selected_tags   : this.newsDetails.tags?.map(tag => tag.name) || [],
      newsPhoto       : this.newsDetails.image || "",
      date            : new Date(this.newsDetails.date_to_publish) || new Date(),
      tags            : [],
      category_id     : this.newsDetails.category_id || '',
      isTagsLoading   : false,
      isSingleUpload  : false,
      showUploadCenter: false,

    };
  },
  computed: {
    APP_URL: function () {
      return process.env.VUE_APP_IMAGE_REMOTE_URL;
    },

    ...mapGetters(['categories'])
  },
  watch   : {
    date(newVal)
    {
      this.$emit("update", "date_to_publish", newVal);
    },
    selected_tags(newVal)
    {
      this.$emit("update", "tags", newVal);
    },
    category_id(newVal)
    {
      this.$emit("update", "category_id", newVal);
    }
  },
  mounted()
  {
    store.dispatch('fetchCategories')
  },
  methods: {
    isRequired(value)
    {
      return value ? true : 'This field is required';
    },
    handleSearchTags(val)
    {
      if (val)
      {
        this.isTagsLoading = true;
        fetchSearchTags(val).then((res) => {
          this.tags          = res.data;
          this.isTagsLoading = false;
        });
      }
    },
    handleOpenUploadCenter()
    {
      this.showUploadCenter = true;
      this.isSingleUpload   = true;
    },
    closeUploadCenter()
    {
      this.showUploadCenter = false;
      this.isSingleUpload   = false;
    },
    handleAddPhoto(photo)
    {
      console.log(photo)
      if (this.showUploadCenter && this.isSingleUpload)
      {
        this.newsPhoto = photo.url;
        this.$emit("update", "image", photo.url);
      }
      this.closeUploadCenter();
    },
  },
};
</script>

<style lang="scss">
.details-form {


  .news-image {
    .placeholder-image {
      img {
        width: 50px;
        object-fit: cover;
        object-position: center;
      }
    }
  }

  .checkbox-group {
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    padding-inline-start: 8px;

    [type="checkbox"] {
      vertical-align: middle;
      width: 1rem;
      height: 1rem;
      border: 1px solid #dadce7;
    }

    label {
      cursor: pointer;
      margin-inline-start: 5px;
      margin-bottom: 0;
      font-size: 0.78rem;
      font-weight: 500;
    }
  }

  .editor-box {
    .ql-container {
      overflow: auto;
      width: 100%;
      min-height: 330px;
    }
  }

  .tags-control {
    .form-control {
      padding: 0;
    }


    .multiselect-input {
      height: 100%;
    }
  }

  .el-select-dropdown,
  .el-select-dropdown__list,
  .el-select__popper,
  .el-select-dropdown__wrap {
    max-width: 300px;
  }


  .multi-select {

    .el-select__input {
      margin: 0;
    }

    .el-select__tags,
    input {
      padding: 0 5px;
      max-width: 100% !important;
    }

    .el-input__inner {
      padding: 0 10px;
      min-height: 42px !important;
      width: 100%;
    }

    .el-select__tags .el-tag {
      padding: 2px 6px 2px 0;
    }

    .el-select__tags .el-tag--info {
      background-color: #15aec2;
      color: #fff;
    }

    .el-tag--default .el-tag__close {
      margin-right: 6px;
      align-self: end;
      background-color: rgba(255, 255, 255, 0.9);
      margin-bottom: 2px;

      :hover {
        color: rgba(0, 0, 0, 0.8);
      }
    }


  }

  .el-select .el-select__tags-text {
    min-height: 20px;
    font-weight: bold;
  }

  .el-input__inner {
    min-height: 42px;
  }

  .ck-editor__editable_inline {
    min-height: 350px;
  }
}
</style>
